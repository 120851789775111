import Navigo from 'navigo';

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload (link) {
    // Add active state to sidbar nav links
    $(".navbar a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
        if (this.href.search(link) !== -1) {
            $(this).addClass("active");
            // console.log(this.href+' Active Search: '+this.href.search(link));
            // $(this).closest('.collapse').addClass('show').prev('.nav-link-collapser').removeClass('collapsed').addClass('active');
        }
    });
    if(link=='index.html') $('.nav-link-def-route').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    $.sessionStorage.setItem('myPage', link); // Change current page to session
    // Smooth Scroll to div...
    App.refreshSmoothScroll();
    // Add the following code if you want the name of the file appear on select
    $(".custom-file-input").on("change", function(e) {
        //const fileName = $(this).val().split("\\").pop();
        let fileName = "";
        //e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
        //alert(e.target.files[0].name);
        for (var i = 0; i < e.target.files.length; i++) {
            fileName += '"'+e.target.files[i].name+'" ';
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
}

const myDomain = window.location.origin; // https://my.domain
const rootUrl = (myDomain.indexOf('localhost')!==-1) ? '/' : '/'; // if online version is in a subfolder
const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
// use #! to hash
// const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
router.hooks({
    // Ensure new data-navigo links work.
    after: function() {
        router.updatePageLinks();
    },
});
const routerContainer = document.getElementById('routerContainer');
const routerContainerExist = (routerContainer) ? true : false;
if(!routerContainerExist) document.location.href = './';
// Direct To Some Page...
// const goTo = App.urlParam('goto', document.URL); // Where are we going
// if(goTo == 'page') router.navigate("/page");

router.on({
    // 'routerContainer' is the id of the div element inside which we render the HTML
    '/': () => {
        fetch('/catalogue.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setHomePage();
            setPageAfterload('index.html');
            // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/home': () => {
        fetch('/catalogue.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setHomePage();
            setPageAfterload('home');
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/product/:id': ({data}) => {
        fetch('/product.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setProductPage(data.id);
            setPageAfterload('product');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/cart': () => {
        fetch('/cart.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setCartPage();
            setPageAfterload('cart');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/codegen': () => {
        fetch('/codegen.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setCodeGenPage();
            setPageAfterload('codegen');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/codegen/:id': ({data}) => {
        fetch('/codegen.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setCodeGenPage(data.id);
            setPageAfterload('codegen');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/codemod': () => {
        fetch('/codemod.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setCodeModPage();
            setPageAfterload('codemod');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/codemod/:id': ({data}) => {
        fetch('/codemod.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setCodeModPage(data.id);
            setPageAfterload('codemod');
            // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/contact': () => {
        fetch('/contact.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setContactPage();
            setPageAfterload('contact');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/about': () => {
        fetch('/about.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setAboutPage();
            setPageAfterload('about');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/register': () => {
        fetch('/register.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setRegisterPage();
            setPageAfterload('register');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/login': () => {
        fetch('/login.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setLoginPage();
            setPageAfterload('login');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/account': () => {
        fetch('/account.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setAccountPage();
            setPageAfterload('account');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/delivery': () => {
        fetch('/delivery.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setDeliveryPage();
            setPageAfterload('delivery');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/order': () => {
        fetch('/order.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setOrderPage();
            setPageAfterload('order');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/paypage': () => {
        fetch('/paypage.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setPayPage();
            setPageAfterload('paypage');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/confirmation': () => {
        fetch('/confirmation.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.sendOrder();
            setPageAfterload('confirmation');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    },
    '/deconnexion': () => {
        App.logMeOut();
        // loadHTML('/logout.html', 'routerContainer');
    },
});

router.on(() => {
    fetch('/catalogue.html').then(function (response) {
        return response.text(); // To fetch html below we fetch json as we usually do...
    }).then(function (html) {
        routerContainer.innerHTML = html;
        App.getProductList();
        setPageAfterload('index.html');
        // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
    }).catch(function (err) {
        console.warn('Something went wrong.', err);
    });
});

// set the 404 route
router.notFound((query) => {
    // loadHTML('/404.html', 'routerContainer');
    console.warn('404 Route not found');
    console.warn(query);
    fetch('/catalogue.html').then(function (response) {
        return response.text(); // To fetch html below we fetch json as we usually do...
    }).then(function (html) {
        routerContainer.innerHTML = html;
        App.setHomePage();
        setPageAfterload('index.html');
        // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
    }).catch(function (err) {
        console.warn('Something went wrong.', err);
    });
});

router.resolve();
/*
fetch('http://example.org').then(function(response) {
    if (response.ok) {
        return response.json()
    } else {
        throw ("Error " + response.status);
    }
}).then(function(data) {
    // do what you have to do with data
}).catch(function(err) {
    console.log(err);
});
*/
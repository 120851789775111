// Import CSS
import '../bootstrap/css/bootstrap.min.css';
import '../css/best-carousel-slide.css';
import '../css/animate.min.css';
import '../css/simplelightbox.css';
import '../css/smoothproducts.css';
import '../css/styles.css';
// Import JS
import 'bootstrap';
// import Navigo from 'navigo';
// import './bootstrap.bundle.min.js';
import './font-awesome-5.11.2-all.min.js';
import './jquery.html5storage.min.js';
// import './clean-blog.js';
// import './smooth.scroll.anchor.js';
import './simplelightbox.min.js';
import './smoothproducts.min.js';
import regeneratorRuntime from "regenerator-runtime"; // Async/Await made available here
import tinycolor from 'tinycolor2';
import { loadScript } from "@paypal/paypal-js";
// Set new default font family and font color to mimic Bootstrap's default styling
// Chart.defaults.global.defaultFontFamily = 'Metropolis, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
// Chart.defaults.global.defaultFontColor = "#858796";

var globals,
App = {
	/*
	<option value="France" selected="">France</option>
	<option value="Deutschland">Deutschland</option>
	<option value="Andorra">Andorra</option>
	<option value="Austria">Austria</option>
	<option value="Belgique">Belgique</option>
	<option value="Canada">Canada</option>
	<option value="Danemark">Danemark</option>
	<option value="España">España</option>
	<option value="United States">United States</option>
	<option value="Finland">Finland</option>
	<option value="Greece">Greece</option>
	<option value="Ireland">Ireland</option>
	<option value="Italia">Italia</option>
	<option value="Luxembourg">Luxembourg</option>
	<option value="Martinique">Martinique</option>
	<option value="Nederland">Nederland</option>
	<option value="Poland">Poland</option>
	<option value="Polynésie Française">Polynésie Française</option>
	<option value="Portugal">Portugal</option>
	<option value="United Kingdom">United Kingdom</option>
	<option value="Sweden">Sweden</option>
	<option value="Suisse">Suisse</option>
*/
	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		getFilter: false,
		myEvent: 'loadEvent',
		cart: [], // Will be an array of objects
		i18nIsoCountries: [{country: 'France', code: 'FR'}, {country: 'Deutschland', code: 'DE'}, {country: 'Andorra', code: 'AD'}, {country: 'Austria', code: 'AT'}, {country: 'Belgique', code: 'BE'}, {country: 'Canada', code: 'CA'}, {country: 'España', code: 'ES'}, {country: 'United States', code: 'US'}, {country: 'Finland', code: 'FI'}, {country: 'Greece', code: 'GR'}, {country: 'Ireland', code: 'IE'}, {country: 'Italia', code: 'IT'}, {country: 'Luxembourg', code: 'LU'}, {country: 'Martinique', code: 'MQ'}, {country: 'Nederland', code: 'NL'}, {country: 'Poland', code: 'PL'}, {country: 'Polynésie Française', code: 'PF'}, {country: 'Portugal', code: 'PT'}, {country: 'United Kingdom', code: 'UK'}, {country: 'Sweden', code: 'SE'}, {country: 'Suisse', code: 'CH'}],
		cartBadge: 0,
		serverAddress: "https://rassure.store/db_shop.php",
		// serverWsAddress: "https://jonsenisland.com/com/ws_shop.php",
		// serverWsAddress: "https://rassure.store/ws_shop.php",
		year: (new Date).getFullYear(),
		reassurance: '',
		previousImpactPrice: 0,
		uploadQrBgImg: false,
		myPage: $.sessionStorage.getItem('myPage'),
		qrId: $.sessionStorage.getItem('qrId'),
		idOrder: $.sessionStorage.getItem('idOrder'),
		idOrderLine: $.sessionStorage.getItem('idOrderLine'),
		idCarrier: $.sessionStorage.getItem('idCarrier'),
		priceTotal: $.sessionStorage.getItem('priceTotal'),
		priceDelivery: $.sessionStorage.getItem('priceDelivery'),
		pictureName: $.sessionStorage.getItem('pictureName'),
		pass: $.localStorage.getItem('pass'),
		id: $.localStorage.getItem('id'),
		idPresta: $.localStorage.getItem('idPresta'),
		id_gender_u: $.localStorage.getItem('id_gender_u'),
		firstname_u: $.localStorage.getItem('firstname_u'),
		lastname_u: $.localStorage.getItem('lastname_u'),
		email_u: $.localStorage.getItem('email_u'),
		phone_u: $.localStorage.getItem('phone_u'),
		birthday_u: $.localStorage.getItem('birthday_u'),
		idAddress: $.localStorage.getItem('idAddress'),
		delivery_address_u: $.localStorage.getItem('delivery_address_u'),
		delivery_address2_u: $.localStorage.getItem('delivery_address2_u'),
		delivery_zip_u: $.localStorage.getItem('delivery_zip_u'),
		delivery_city_u: $.localStorage.getItem('delivery_city_u'),
		delivery_country_u: $.localStorage.getItem('delivery_country_u'),
		idAddressInvoice: $.localStorage.getItem('idAddressInvoice'),
		invoice_address_u: $.localStorage.getItem('invoice_address_u'),
		invoice_address2_u: $.localStorage.getItem('invoice_address2_u'),
		invoice_zip_u: $.localStorage.getItem('invoice_zip_u'),
		invoice_city_u: $.localStorage.getItem('invoice_city_u'),
		invoice_country_u: $.localStorage.getItem('invoice_country_u'),
		idCountry: $.localStorage.getItem('idCountry'),
		active_u: $.localStorage.getItem('active_u'),
		pwd: $.localStorage.getItem('pwd'),
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.id = data.auto_u;
		globals.idPresta = data.customerId;
		globals.id_gender_u = data.id_gender_u;
		globals.firstname_u = data.firstname_u;
		globals.lastname_u = data.lastname_u;
		globals.email_u = data.email_u;
		globals.phone_u = data.phone_u;
		globals.birthday_u = data.birthday_u;
		globals.idAddress = data.addressId;
		globals.delivery_address_u = data.delivery_address_u;
		globals.delivery_address2_u = data.delivery_address2_u;
		globals.delivery_zip_u = data.delivery_zip_u;
		globals.delivery_city_u = data.delivery_city_u;
		globals.delivery_country_u = data.delivery_country_u;
		globals.idAddressInvoice = data.addressInvoiceId;
		globals.invoice_address_u = data.invoice_address_u;
		globals.invoice_address2_u = data.invoice_address2_u;
		globals.invoice_zip_u = data.invoice_zip_u;
		globals.invoice_city_u = data.invoice_city_u;
		globals.invoice_country_u = data.invoice_country_u;
		globals.idCountry = data.idCountry;
		globals.active_u = data.active_u;
	},
	init: function() {
		// kick things off
		globals = this.settings;
		this.bindUIActions();
		// this.getProductList();
		$("#now-date").append(globals.year);
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case there is an order so we update front_codes with userId.
		query = query + "&idOrder=" + globals.idOrder + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('id', data.auto_u);
				$.localStorage.setItem('idPresta', data.customerId);
				$.localStorage.setItem('id_gender_u', data.id_gender_u);
				$.localStorage.setItem('company_u', data.company_u);
				$.localStorage.setItem('siret_u', data.siret_u);
				$.localStorage.setItem('firstname_u', data.firstname_u);
				$.localStorage.setItem('lastname_u', data.lastname_u);
				$.localStorage.setItem('email_u', data.email_u);
				$.localStorage.setItem('phone_u', data.phone_u);
				$.localStorage.setItem('birthday_u', data.birthday_u);
				$.localStorage.setItem('idAddress', data.addressId);
				$.localStorage.setItem('delivery_address_u', data.delivery_address_u);
				$.localStorage.setItem('delivery_address2_u', data.delivery_address2_u);
				$.localStorage.setItem('delivery_zip_u', data.delivery_zip_u);
				$.localStorage.setItem('delivery_city_u', data.delivery_city_u);
				$.localStorage.setItem('delivery_country_u', data.delivery_country_u);
				$.localStorage.setItem('idAddressInvoice', data.addressInvoiceId);
				$.localStorage.setItem('invoice_address_u', data.invoice_address_u);
				$.localStorage.setItem('invoice_address2_u', data.invoice_address2_u);
				$.localStorage.setItem('invoice_zip_u', data.invoice_zip_u);
				$.localStorage.setItem('invoice_city_u', data.invoice_city_u);
				$.localStorage.setItem('invoice_country_u', data.invoice_country_u);
				$.localStorage.setItem('idCountry', data.id_country_u);
				$.localStorage.setItem('active_u', data.active_u);
				App.refreshGlobals(data);
				$('#userNameCont').empty().append('Bienvenue<br>'+globals.firstname_u+' '+globals.lastname_u);
				$('.connected').show();
				$('.notConnected').hide();
				if(globals.cart.length>0) {
					setTimeout(function(){
						// document.location.href='/delivery';
						document.location.href='/paypage';
					}, 600);
				}
				else {
					setTimeout(function(){
						window.history.back();
					}, 600);
				}
			}
			else {
				if(data.active_u==0) alert("Ce compte a été désactivé !");
				else alert("Identifiant ou mot de passe erroné !");
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-unlock"></i> Connexion</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		$.localStorage.setItem('pass', false);
		$.localStorage.setItem('id', '');
		$.localStorage.setItem('idPresta', '');
		$.localStorage.setItem('id_gender_u', '');
		$.localStorage.setItem('company_u', '');
		$.localStorage.setItem('siret_u', '');
		$.localStorage.setItem('firstname_u', '');
		$.localStorage.setItem('lastname_u', '');
		$.localStorage.setItem('email_u', '');
		$.localStorage.setItem('phone_u', '');
		$.localStorage.setItem('birthday_u', '');
		$.localStorage.setItem('idAddress', '');
		$.localStorage.setItem('delivery_address_u', '');
		$.localStorage.setItem('delivery_address2_u', '');
		$.localStorage.setItem('delivery_zip_u', '');
		$.localStorage.setItem('delivery_city_u', '');
		$.localStorage.setItem('delivery_country_u', '');
		$.localStorage.setItem('idAddressInvoice', '');
		$.localStorage.setItem('invoice_address_u', '');
		$.localStorage.setItem('invoice_address2_u', '');
		$.localStorage.setItem('invoice_zip_u', '');
		$.localStorage.setItem('invoice_city_u', '');
		$.localStorage.setItem('invoice_country_u', '');
		$.localStorage.setItem('idCountry', '');
		$.localStorage.setItem('active_u', false);
		$('.connected').hide();
		$('.notConnected').show();
		setTimeout(function(){
			document.location.href='/'; // Or login.html if multipage
		}, 1000);
	},
	registerWs: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		let req = "register";
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case there is an order so we update front_codes with userId.
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		// query = query + "&" + checkBoxes + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		query = query + "&idOrder=" + globals.idOrder + "&req=" + req;
		query = query + "&id=" + globals.id + "&idOrder=" + idOrder + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=='ok') {
				$(myFormDiv+' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle"></i>&nbsp; Votre compte a bien été créé.</b></div>');
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('id', data.auto_u);
				$.localStorage.setItem('idPresta', data.customerId);
				$.localStorage.setItem('id_gender_u', data.id_gender_u);
				$.localStorage.setItem('company_u', data.company_u);
				$.localStorage.setItem('siret_u', data.siret_u);
				$.localStorage.setItem('firstname_u', data.firstname_u);
				$.localStorage.setItem('lastname_u', data.lastname_u);
				$.localStorage.setItem('email_u', data.email_u);
				$.localStorage.setItem('phone_u', data.phone_u);
				$.localStorage.setItem('birthday_u', data.birthday_u);
				$.localStorage.setItem('idAddress', data.addressId);
				$.localStorage.setItem('delivery_address_u', data.delivery_address_u);
				$.localStorage.setItem('delivery_address2_u', data.delivery_address2_u);
				$.localStorage.setItem('delivery_zip_u', data.delivery_zip_u);
				$.localStorage.setItem('delivery_city_u', data.delivery_city_u);
				$.localStorage.setItem('delivery_country_u', data.delivery_country_u);
				$.localStorage.setItem('idAddressInvoice', data.addressInvoiceId);
				$.localStorage.setItem('invoice_address_u', data.invoice_address_u);
				$.localStorage.setItem('invoice_address2_u', data.invoice_address2_u);
				$.localStorage.setItem('invoice_zip_u', data.invoice_zip_u);
				$.localStorage.setItem('invoice_city_u', data.invoice_city_u);
				$.localStorage.setItem('invoice_country_u', data.invoice_country_u);
				$.localStorage.setItem('idCountry', data.countryId);
				$.localStorage.setItem('active_u', data.active_u);
				App.refreshGlobals(data);
				if(globals.cart.length>0) {
					setTimeout(function(){
						// document.location.href='/delivery';
						document.location.href='/paypage';
					}, 1600);
				}
			}
			else $(myFormDiv+' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle"></i> '+data.alertSentence+'</b></div>');
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-save"></i> Enregistrer');
		});
	},
	updateAccountWs: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		let req = "updateAccount";
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		// query = query + "&" + checkBoxes + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case there is an order so we update front_codes with userId.
		query = query + "&id=" + globals.id + "&idPresta=" + globals.idPresta + "&idOrder=" + idOrder + "&idAddress=" + globals.idAddress + "&idAddressInvoice=" + globals.idAddressInvoice + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=='ok') {
				$(myFormDiv+' #successfail').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle"></i>&nbsp;Votre compte a bien été mis à jour.</b></div>');
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('id', data.auto_u);
				$.localStorage.setItem('idPresta', data.customerId);
				$.localStorage.setItem('id_gender_u', data.id_gender_u);
				$.localStorage.setItem('company_u', data.company_u);
				$.localStorage.setItem('siret_u', data.siret_u);
				$.localStorage.setItem('firstname_u', data.firstname_u);
				$.localStorage.setItem('lastname_u', data.lastname_u);
				$.localStorage.setItem('email_u', data.email_u);
				$.localStorage.setItem('phone_u', data.phone_u);
				$.localStorage.setItem('birthday_u', data.birthday_u);
				$.localStorage.setItem('idAddress', data.addressId);
				$.localStorage.setItem('delivery_address_u', data.delivery_address_u);
				$.localStorage.setItem('delivery_address2_u', data.delivery_address2_u);
				$.localStorage.setItem('delivery_zip_u', data.delivery_zip_u);
				$.localStorage.setItem('delivery_city_u', data.delivery_city_u);
				$.localStorage.setItem('delivery_country_u', data.delivery_country_u);
				$.localStorage.setItem('idAddressInvoice', data.addressInvoiceId);
				$.localStorage.setItem('invoice_address_u', data.invoice_address_u);
				$.localStorage.setItem('invoice_address2_u', data.invoice_address2_u);
				$.localStorage.setItem('invoice_zip_u', data.invoice_zip_u);
				$.localStorage.setItem('invoice_city_u', data.invoice_city_u);
				$.localStorage.setItem('invoice_country_u', data.invoice_country_u);
				$.localStorage.setItem('idCountry', data.countryId);
				$.localStorage.setItem('active_u', data.active_u);
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 600);
				if(globals.cart.length>0) {
					setTimeout(function(){
						// document.location.href='/delivery';
						document.location.href='/paypage';
					}, 1600);
				}
			}
			else $(myFormDiv+' #successfail').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle"></i> '+data.alertSentence+'</b></div>');
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i> Valider');
		});
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b>Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false).html('Envoyer&nbsp;<i class="fa fa-paper-plane"></i>');
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
	},
	closeModal: function (myModalDiv) {
		$(myModalDiv).modal('hide');
	},
	clearFormFields: function(myFormDiv, event) {
		// if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		// $(myFormDiv).find("input[type=text], input[type=tel], input[type=email], input[type=number], input[type=file], textarea, select").val("");
		$(myFormDiv).find("input, textarea, select").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url){
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	getProductList: function() {
		$.post(globals.serverAddress, {req: 'getProductList'}, function(data){
			$("#productsListCont").empty().append(data.snippet);
			$("#reassuranceCont").empty().append(data.reassurance);
			$.localStorage.setItem('reassurance', data.reassurance);
			globals.reassurance = data.reassurance;
		}, "json").done(function(data) {
			// And set the LightBox...
			let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
				showCounter : false,
				history : false,
				captionType : 'data',
				captionsData : 'caption'
			});
			lightbox.refresh();
		});
	},
	setProductPage: function(productId) {
		$.post(globals.serverAddress, {id_product: productId, req: 'getProductDetails'}, function(data){
			if(data.ok=='ok') {
				$("#productDetailsCont #productName").empty().append(data.productName);
				$("#productDetailsCont #productNameInfo").empty().append(data.productName);
				$("#productDetailsCont #productPrice").empty().append(data.productPrice);
				$("#productDetailsCont #productDesc").empty().append(data.productDesc);
				$("#productDetailsCont #productDescShort").empty().append(data.productDescShort);
				$("#productDetailsCont #productViewBox").empty().append(data.productViewBox);
				$("#productDetailsCont #productDescTab").empty().append(data.productDescShort+'<hr>'+data.productDesc+'<hr><table class="table table-hover table-striped"><tr><td>Référence :</td><td>'+data.productReference+'</td></tr><tr><td>En stock (total) :</td><td>'+data.productQuantity+'</td></tr><tr><td>Largeur :</td><td>'+data.productWidth+'</td></tr><tr><td>Hauteur :</td><td>'+data.productHeight+'</td></tr><tr><td>Profondeur :</td><td>'+data.productDepth+'</td></tr><tr><td>Poids :</td><td>'+data.productWeight+'</td></tr></table>');
				// $("#productDetailsCont #productDescTab").empty().append(data.productDescShort+'<hr>'+data.productDesc+'<hr><table class="table table-hover table-striped"><tr><td>Référence :</td><td>'+data.productReference+'</td></tr><tr><td>En stock (total) :</td><td>'+data.productQuantity+'</td></tr><tr><td>Largeur :</td><td>'+data.productWidth+'</td></tr><tr><td>Hauteur :</td><td>'+data.productHeight+'</td></tr><tr><td>Profondeur :</td><td>'+data.productDepth+'</td></tr><tr><td>Poids :</td><td>'+data.productWeight+'</td></tr></table>');
				// Building addToCartForm...
				const productDetails = App.safeJsonParse(data.productDetails);
				const productPrice = productDetails.price;
				$("#addToCartForm #id_product").val(productId);
				$("#addToCartForm #product_price").val(productDetails.price);
				$("#addToCartForm #product_name").val(data.productName);
				$("#addToCartForm #product_desc").val(data.productDescShortNoTag);
				$("#addToCartForm #product_img").val(data.productImg);
				$("#addToCartForm #product_ref").val(data.productReference);
				$("#addToCartForm #uploadable_files").val(productDetails.uploadable_files);
				$("#addToCartForm #customizable").val(productDetails.customizable);
				$("#addToCartForm #frame").val(productDetails.frame);
				if(data.productQuantity<1) {
					$("productDetailsCont #addToCartBtn").attr('disabled', true).removeClass('btn-info').addClass('btn-dark').html('<i class="fa fa-times-circle"></i> Stock épuisé');
				}
				else {
					$("productDetailsCont #addToCartBtn").attr('disabled', false).removeClass('btn-dark').addClass('btn-info').html('<i class="fa fa-cart-plus"></i> Ajouter au panier');
					// Building combinations selectBoxes...
					const attributesArray = productDetails.id_attributes;
					const productAttributesArray = productDetails.id_product_attributes;
					const combinationsQuantityArray = productDetails.comb_quantities;
					const attributesNameArray = productDetails.attr_names;
					const attributesTypeArray = productDetails.attr_types;
					const attributesImageArray = productDetails.attr_images;
					const colorsArray = productDetails.colors;
					let sizeSelect = '<div class="input-group my-2"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-arrows-alt"></i></span></div><select class="form-control form-control-lg custom-select" name="sizeSelect" id="sizeSelect" onChange="App.getCombinationAvailability(\''+productId+'\', this);" required><option value="" selected>Taille</option>';
					let colorSelect = '<div class="input-group my-2"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-paint-brush"></i></span></div><select class="form-control form-control-lg custom-select" name="colorSelect" id="colorSelect" onChange="App.getCombinationAvailability(\''+productId+'\', this);" required><option value="" selected>Couleur</option>';
					let dimSelect = '<div class="input-group my-2"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-ruler-combined"></i></span></div><select class="form-control form-control-lg custom-select" name="dimSelect" id="dimSelect" onChange="App.getCombinationAvailability(\''+productId+'\', this);" required><option value="" selected>Dimensions</option>';
					let supportSelect = '<div class="input-group my-2"><div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-layer-group"></i></span></div><select class="form-control form-control-lg custom-select" name="supportSelect" id="supportSelect" onChange="App.getCombinationAvailability(\''+productId+'\', this);" required><option value="" selected>Support</option>';
					let howManySize = 0;
					let howManyColor = 0;
					let howManyDim = 0;
					let howManySupport = 0;
					if(attributesArray) {
						attributesArray.forEach((idAttibute, index) => {
							console.log(idAttibute+' '+productAttributesArray[index]+' '+combinationsQuantityArray[index]+' '+attributesNameArray[index]+' '+attributesTypeArray[index]+' '+attributesImageArray[index]+' '+colorsArray[index]);
							if(data.howManyAttributesGroup>1 || combinationsQuantityArray[index]>1) {
								if(attributesTypeArray[index] == 'Taille') {
									sizeSelect += '<option value="'+attributesArray[index]+'">'+attributesNameArray[index]+'</option>';
									howManySize++;
								}
								if(attributesTypeArray[index] == 'Couleur') {
									colorSelect += '<option value="'+attributesArray[index]+'" onclick="App.colorSelection(\''+attributesImageArray[index]+'\');">'+attributesNameArray[index]+'</option>';
									howManyColor++;
								}
								if(attributesTypeArray[index] == 'Dimension') {
									dimSelect += '<option value="'+attributesArray[index]+'">'+attributesNameArray[index]+'</option>';
									howManyDim++;
								}
								if(attributesTypeArray[index] == 'Support') {
									supportSelect += '<option value="'+attributesArray[index]+'">'+attributesNameArray[index]+'</option>';
									howManySupport++;
								}
							}
						});
					}
					sizeSelect += '</select></div>';
					colorSelect += '</select></div>';
					dimSelect += '</select></div>';
					supportSelect += '</select></div>';
					//console.log(sizeSelect);
					$("#productDetailsCont #attributeSelectCont").empty();
					if(howManySize>0) $("#productDetailsCont #attributeSelectCont").append(sizeSelect);
					if(howManyColor>0) $("#productDetailsCont #attributeSelectCont").append(colorSelect);
					if(howManyDim>0) $("#productDetailsCont #attributeSelectCont").append(dimSelect);
					if(howManySupport>0) $("#productDetailsCont #attributeSelectCont").append(supportSelect);
					setTimeout(function() {
						if(howManySize==1) $("#sizeSelect :first-child").attr("selected", false).next('option').attr("selected", true);
						if(howManyColor==1) $("#colorSelect :first-child").attr("selected", false).next('option').attr("selected", true);
						if(howManyDim==1) $("#dimSelect :first-child").attr("selected", false).next('option').attr("selected", true);
						if(howManySupport==1) $("#supportSelect :first-child").attr("selected", false).next('option').attr("selected", true);
					}, 100);
				}
				// Building meta tags list...
				$('head .productMeta').remove();
				const meta_title = (productDetails.meta_title!='') ? productDetails.meta_title : data.productName;
				const meta_description = (productDetails.meta_title!='') ? productDetails.meta_description : data.productDescNoTag;
				$('head title').text(meta_title);
				$('head meta[name="description"]').text(meta_description);
				$('head meta[name="keywords"]').text(productDetails.meta_keywords);
				$('head').append('<meta property="og:type" content="product" class="productMeta">');
				$('head').append('<meta property="og:url" content="'+window.location.href+'" class="productMeta">');
				$('head').append('<meta property="og:title" content="'+meta_title+'" class="productMeta">');
				$('head').append('<meta property="og:description" content="'+meta_description+'" class="productMeta">');
				$('head').append('<meta property="og:image" content="'+data.productImg+'" class="productMeta">');
				$('head').append('<meta property="product:pretax_price:amount" content="'+productDetails.pretax_price+'" class="productMeta">');
				$('head').append('<meta property="product:price:amount" content="'+productDetails.price+'" class="productMeta">');
				$('head').append('<meta property="product:pretax_price:currency" content="EUR" class="productMeta">');
				$('head').append('<meta property="product:price:currency" content="EUR" class="productMeta">');
				// Building Social Networks Sharing Buttons List
				// See full list of social networks sharing urls at: https://github.com/bradvin/social-share-urls
				// https://www.facebook.com/sharer.php?u=https%3A%2F%2Fcommandes.rassure.store%2Fhommes%2F1-hummingbird-printed-t-shirt.html
				// https://twitter.com/intent/tweet?text=T-shirt+imprim%C3%A9+colibri https%3A%2F%2Fcommandes.rassure.store%2Fhommes%2F1-hummingbird-printed-t-shirt.html
				// https://www.pinterest.com/pin/create/button/?media=https%3A%2F%2Fcommandes.rassure.store%2F1%2Fhummingbird-printed-t-shirt.jpg&url=https%3A%2F%2Fcommandes.rassure.store%2Fhommes%2F1-hummingbird-printed-t-shirt.html
				// https://www.linkedin.com/sharing/share-offsite/?url={url}
				const id_cover_img = productDetails.id_cover_img;
				const linkImg = 'https://commandes.rassure.store/'+id_cover_img+'/'+id_cover_img+'.jpg';
				$('#shareOnTwitter').attr('href', 'https://twitter.com/intent/tweet?text='+data.productName+' '+window.location);
				$('#shareOnFacebook').attr('href', 'https://www.facebook.com/sharer.php?u='+window.location);
				$('#shareOnLinkedIn').attr('href', 'https://www.linkedin.com/sharing/share-offsite/?url='+window.location);
				$('#shareOnPinterest').attr('href', 'https://www.pinterest.com/pin/create/button/?media='+linkImg+'&url='+window.location);
			}
			else {
				alert("Aucun produit ne correspond à cette identifiant !");
				document.location.href='/';
			}
		}, "json").done(function(data) {
            App.smoothScrollTo('#productDetailsCont', 0);
			// And set the LightBox...
			let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
				showCounter : false,
				history : false,
				captionType : 'data',
				captionsData : 'caption'
			});
			lightbox.refresh();
			if ($('.clean-product').length > 0) {
				$('.sp-wrap').smoothproducts();
			}
			// Set swipe gestures on smoohtproduct's lightbox
			$('.sp-lightbox').on('swipeleft',function(){
				$('a.sp-prev').trigger('click');
			});
			$('.sp-lightbox').on('swiperight',function(){
				$('a.sp-next').trigger('click');
			});
		});
		$("#productDetailsCont #productSpecs").append($.localStorage.getItem('reassurance'));
	},
	getCombinationAvailability: function(idProduct, thisBox) {
		// console.log("IN getCombinationAvailability: "+idProduct)
		let attributes = [];
		if($('#sizeSelect').val()!=undefined) attributes.push($('#sizeSelect').val());
		if($('#colorSelect').val()!=undefined) attributes.push($('#colorSelect').val());
		if($('#dimSelect').val()!=undefined) attributes.push($('#dimSelect').val());
		$.post(globals.serverAddress, {id_product: idProduct, attributes: attributes, req: 'getCombinationAvailability'}, function(data){
			if(data.quantity<1) {
				$("productDetailsCont #addToCartBtn").attr('disabled', true).removeClass('btn-info').addClass('btn-dark').html('<i class="fa fa-times-circle"></i> Stock épuisé');
			}
			// Handling pricing change for some combinations
			if(globals.previousImpactPrice!=0) { // Was there a pricing change before ?
				let newPrice = Number($("#addToCartForm #product_price").val());
				newPrice = (globals.previousImpactPrice>0) ? (newPrice - globals.previousImpactPrice) : (newPrice + globals.previousImpactPrice);
				$("#addToCartForm #product_price").val(newPrice);
				$("#addToCartForm #product_price").val(newPrice);
				const newPriceFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(newPrice);
				$("#productDetailsCont #productPrice").empty().append(newPriceFormat);
				globals.previousImpactPrice = 0;
			}
			if(data.price!=0) { // Applying pricing change now..
				let newPrice = Number($("#addToCartForm #product_price").val());
				newPrice = (data.price>0) ? (newPrice + data.price) : (newPrice - data.price);
				$("#addToCartForm #product_price").val(newPrice);
				$("#addToCartForm #product_price").val(newPrice);
				const newPriceFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(newPrice);
				$("#productDetailsCont #productPrice").empty().append(newPriceFormat);
				globals.previousImpactPrice = data.price;
			}
		}, "json").done(function(data) {
		});
	},
	colorSelection: function(imageId) {
		console.log("IN colorSelection: "+imageId);
		if(imageId) $('.sp-thumbs a[href="https://commandes.rassure.store/'+imageId+'/'+imageId+'.jpg"]').trigger('click');
	},
	addToCart: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "addToCart";
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case but very unlikely to happen as sessionStorage item is only set here.
		let attributes = [];
		let product_choices = [];
		if($(myFormDiv+' #sizeSelect').val()!=undefined) {
			attributes.push($(myFormDiv+' #sizeSelect').val());
			product_choices.push("Taille : "+$(myFormDiv+' #sizeSelect option:selected').text());
		}
		if($(myFormDiv+' #colorSelect').val()!=undefined) {
			attributes.push($(myFormDiv+' #colorSelect').val());
			product_choices.push("Couleur : "+$(myFormDiv+' #colorSelect option:selected').text());
		}
		if($(myFormDiv+' #dimSelect').val()!=undefined) {
			attributes.push($(myFormDiv+' #dimSelect').val());
			product_choices.push("Dimensions : "+$(myFormDiv+' #dimSelect option:selected').text());
		}
		query = query + "&req=" + req + "&attributes=" + attributes; // Won't send attributes as an array but as a string !
		const id_product = $(myFormDiv+' #id_product').val();
		const product_price = $(myFormDiv+' #product_price').val();
		const product_quantity = $(myFormDiv+' #product_quantity').val();
		const product_name = $(myFormDiv+' #product_name').val();
		const product_desc = $(myFormDiv+' #product_desc').val();
		const product_img = $(myFormDiv+' #product_img').val();
		const product_ref = $(myFormDiv+' #product_ref').val();
		const uploadable_files = $(myFormDiv+' #uploadable_files').val();
		const customizable = $(myFormDiv+' #customizable').val();
		const frame = $(myFormDiv+' #frame').val();
		const product_choices_db = (product_choices.length>0) ? product_choices.join(' | ') : '';
		$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'idOrder': idOrder, 'id_product': id_product, 'attributes': attributes, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_choices': product_choices_db, "req": "addToCart"}, function(data){
			if(data.ok=='ok') {
				if(data.quantity>=product_quantity) {
					let cartItem = {
						'idOrderLine': data.idOrderLine,
						'id_product': id_product,
						'id_product_attribute': data.id_product_attribute,
						'product_price': Number(product_price).toFixed(2),
						'product_quantity': product_quantity,
						'product_name': product_name,
						'product_desc': product_desc,
						'product_img': product_img,
						'product_ref': product_ref,
						'uploadable_files': uploadable_files,
						'customizable': customizable,
						'frame': frame,
						'product_choices': product_choices,
						'id_code': 0,
						'code_valid': 0
					};
					// Pushing cartItem Object in globals.cart array of objects
					globals.cart.push(cartItem);
					$.sessionStorage.setItem('cart', JSON.stringify(globals.cart));
					console.log(globals.cart);
					// Set or Refresh idOrder
					$.sessionStorage.setItem('idOrder', data.idOrder);
					globals.idOrder = data.idOrder;
					// Set the last idOrderLine generated so we can get it @generateCustomCodes
					$.sessionStorage.setItem('idOrderLine', data.idOrderLine);
					globals.idOrderLine = data.idOrderLine;
					// Handling cartBadge in main menu
					globals.cartBadge++;
					$('#cartBadge').empty().append(globals.cartBadge);
					// Generating addToCartModal's content and pop it... Depending on customizable item or not
					if(customizable!=0) {
						App.buildCartPopUpModal(true);
						setTimeout(function() {
							document.location.href='/codegen';
						}, 2600);
					}
					else App.buildCartPopUpModal(false);
					$('#addToCartModal').modal('show');
				}
				else alert('Malheureusement il ne nous reste pas assez de ce produit en stock !\nStock restant : '+data.quantity);
			}
			else alert("L'ajout au panier n'a pas fonctionné suite à un problème technique !");
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-cart-plus"></i> Ajouter au panier');
		});
	},
	removeItemFromCart: function(itemIndex, cartLocation) {
		// const itemIndex = array.indexOf(5);
		if (itemIndex > -1) {
			const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case but very unlikely to happen see sessionStorage item.
			// Get item's informations before deleting it
			const thisProduct = globals.cart[itemIndex];
			// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
			const idOrderLine = thisProduct.idOrderLine;
			const id_code = thisProduct.id_code;
			globals.cart.splice(itemIndex, 1); // Removes item's Object from array
			$.sessionStorage.setItem('cart', JSON.stringify(globals.cart));
			// Handling cartBadge in main menu
			globals.cartBadge--;
			$('#cartBadge').empty().append(globals.cartBadge);
			if(cartLocation=='page') App.setCartPage(); // Then reloads the page or popup
			else {
				// Refreshing addToCartModal's content...
				if(document.URL.indexOf( 'delivery' ) !== -1) App.setDeliveryPage(); // Delivery page
				else App.buildCartPopUpModal(false);
			}
			console.log(globals.cart);
			$.post(globals.serverAddress, {'idOrderLine': idOrderLine, 'idOrder': idOrder, 'qrId': id_code, "req": "removeItemFromCart"}, function(data){
				if(data.ok=='ok') {
				}
				else console.warn("removeItemFromCart n'a pas fonctionné suite à un problème technique !");
			}, "json").always(function(data){
			});
		}
	},
	changeProductQuantity: function(itemIndex, newQuantity, cartLocation) {
		// const itemIndex = array.indexOf(5);
		if (itemIndex > -1) {
			globals.cart[itemIndex].product_quantity = newQuantity;
			$.sessionStorage.setItem('cart', JSON.stringify(globals.cart));
			// Handling cartBadge in main menu
			// globals.cartBadge--;
			// $('#cartBadge').empty().append(globals.cartBadge);
			console.log(globals.cart);
			const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case but very unlikely to happen as sessionStorage item is only set here.
			// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
			const idOrderLine = globals.cart[itemIndex].idOrderLine;
			const id_code = globals.cart[itemIndex].id_code;
			$.post(globals.serverAddress, {'idOrderLine': idOrderLine, 'idOrder': idOrder, 'newQuantity': newQuantity, "req": "changeProductQuantityInCart"}, function(data){
				if(data.ok=='ok') {
					if(cartLocation=='page') App.setCartPage(); // Then reloads the page or popup
					else {
						// Refreshing addToCartModal's content...
						if(document.URL.indexOf( 'delivery' ) !== -1) App.setDeliveryPage(); // Delivery page
						else App.buildCartPopUpModal(false);
					}
				}
				else console.warn("changeProductQuantityInCart n'a pas fonctionné suite à un problème technique !");
			}, "json").always(function(data){
			});
		}
	},
	buildCartPopUpModal: function(temporary) {
		$('#addToCartModal #itemsInCart').empty();
		let priceTotal = 0;
		// let priceDiscount = 0;
		if(globals.cart.length>0) {
			globals.cart.forEach((item, index) => {
				let snippet = '<div class="product border-bottom p-2">';
				snippet += '<div class="row justify-content-center align-items-center">';
				snippet += '<div class="col-md-3">';
				snippet += '<div class="product-image"><a href="/product/'+item.id_product+'" data-navigo><img class="img-fluid d-block mx-auto image" src="'+item.product_img+'"></a></div>';
				snippet += '</div>'; // col-md-3
				snippet += '<div class="col-md-5 product-info"><a class="product-name" href="/product/'+item.id_product+'" data-navigo>'+item.product_name+'</a>';
				snippet += '<div class="product-specs">';
				if(item.product_choices) {
					item.product_choices.forEach((attr, i) => {
						snippet += '<div><span>'+attr+'</span></div>';
						// snippet += '<div><span>Display:&nbsp;</span><span class="value">5 inch</span></div>';
					});
				}
				if(item.id_code!=0) {
					if(item.code_valid!=0) snippet += '<a class="text-info" href="https://rassure.store/codes/code_'+item.id_code+'.png" data-simplelightbox="all"><i class="fa fa-eye"></i> Voir le QrCode</a>';
					else {
						snippet += '<a class="text-info" href="https://rassure.store/codes/temp/code_'+item.id_code+'.png" data-simplelightbox="all"><i class="fa fa-eye"></i> Voir le QrCode</a>';
						snippet += '<br><a class="text-warning" href="/codegen/'+item.id_code+'" data-navigo><i class="fa fa-qrcode"></i> Régénérer le QrCode</a>';
					}
				}
				else if(item.customizable!=0) {
					snippet += '<a class="text-danger" href="/codegen/" data-navigo><i class="fa fa-exclamation-triangle"></i> Générer le QrCode <i class="fa fa-qrcode"></i></a>';
				}
				snippet += '</div>'; // product-specs
				snippet += '</div>'; // col-md-5
				snippet += '<div class="col-6 col-md-2 quantity">';
				snippet += '<label class="d-none d-md-block" for="quantity">Quantité</label><input type="number" id="number" class="form-control quantity-input" value="'+item.product_quantity+'" min="1" onchange="App.changeProductQuantity(\''+index+'\', this.value, \'pop\')">';
				snippet += '<button class="btn btn-sm btn-danger btn-block" onclick="App.removeItemFromCart(\''+index+'\', \'pop\')"><i class="fa fa-trash-alt"></i></button>';
				snippet += '</div>'; // col-md-2
				const priceFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.product_price*item.product_quantity);
				snippet += '<div class="col-6 col-md-2 font-weight-bolder"><span>'+priceFormat+'</span></div>';
				snippet += '</div>'; // row
				snippet += '</div>'; // product
				$('#addToCartModal #itemsInCart').append(snippet);
				priceTotal = priceTotal + item.product_price*item.product_quantity;
			});
		}
		else {
			$('#addToCartModal #itemsInCart').append('<h2>Votre panier est vide !</h2><img src="https://rassure.store/fonts/undraw_empty_cart_co35.svg" class="img-fluid"/>');
			globals.priceDelivery = 0;
		}
		globals.priceTotal = priceTotal;
		$.sessionStorage.setItem('priceTotal', priceTotal);
		let priceDelivery = (isNaN(globals.priceDelivery) || globals.priceDelivery==null) ? 0 : globals.priceDelivery;
		const priceSubTotal = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal);
		priceTotal = Number(priceTotal)+Number(priceDelivery);
		const priceVat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal/6);
		priceTotal = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal);
		priceDelivery = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceDelivery);
		$('#addToCartModal #cartTotal').text(priceTotal);
		$('#addToCartModal #cartSubTotal').text(priceSubTotal);
		$('#addToCartModal #cartVat').text(priceVat);
		$('#addToCartModal #cartShipping').text(priceDelivery);
		if(temporary) {
			$('#addToCartModal #placeOrderBtn').attr('href', '/codegen').html('<i class="fa fa-forward"></i> Continuer'); // default link is /login
			$('#addToCartModal #redirectToCustomText').empty().append('<h4 class="text-danger">Vous allez être redirigé vers l\'interface de génération de QrCode...</h4>');
		}
		else {
			if(globals.pass == "OK") $('#addToCartModal #placeOrderBtn').attr('href', '/account'); // default link is /login
		}
		// Set the LightBox...
		let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
			showCounter : false,
			history : false,
			captionType : 'data',
			captionsData : 'caption',
			maxZoom: 1,
			widthRatio: 0.6,
			heightRatio: 0.6,
		});
	},
	setCartPage: function() {
		App.smoothScrollTo('#shoppingCartCont', 0);
		const nextPage = (globals.pass == "OK") ? '/account' : '/login'; // default link is /login
		App.buildCart('#shoppingCartCont', nextPage);
		// App.buildCartPopUpModal(false);
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Mon panier - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Mon panier');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	buildCart: function(cartDiv, nextPage) {
		$(cartDiv+' #itemsInCart').empty();
		let priceTotal = 0;
		// let priceDiscount = 0;
		if(globals.cart.length>0) {
			globals.cart.forEach((item, index) => {
				let snippet = '<div class="product border-bottom p-2">';
				snippet += '<div class="row justify-content-center align-items-center">';
				snippet += '<div class="col-md-3">';
				snippet += '<div class="product-image"><a href="/product/'+item.id_product+'" data-navigo><img class="img-fluid d-block mx-auto image" src="'+item.product_img+'"></a></div>';
				snippet += '</div>'; // col-md-3
				snippet += '<div class="col-md-5 product-info"><a class="product-name" href="/product/'+item.id_product+'" data-navigo>'+item.product_name+'</a>';
				snippet += '<div class="product-specs">';
				if(item.product_choices) {
					item.product_choices.forEach((attr, i) => {
						snippet += '<div><span>'+attr+'</span></div>';
						// snippet += '<div><span>Display:&nbsp;</span><span class="value">5 inch</span></div>';
					});
				}
				if(item.id_code!=0) {
					if(item.code_valid!=0) snippet += '<a class="text-info" href="https://rassure.store/codes/code_'+item.id_code+'.png" data-simplelightbox="all"><i class="fa fa-eye"></i> Voir le QrCode</a>';
					else {
						snippet += '<a class="text-info" href="https://rassure.store/codes/temp/code_'+item.id_code+'.png" data-simplelightbox="all"><i class="fa fa-eye"></i> Voir le QrCode</a>';
						snippet += '<br><a class="text-warning" href="/codegen/'+item.id_code+'" data-navigo><i class="fa fa-qrcode"></i> Régénérer le QrCode</a>';
					}
				}
				else if(item.customizable!=0) {
					snippet += '<a class="text-danger" href="/codegen/" data-navigo><i class="fa fa-exclamation-triangle"></i> Générer le QrCode <i class="fa fa-qrcode"></i></a>';
				}
				snippet += '</div>'; // product-specs
				snippet += '</div>'; // col-md-5
				snippet += '<div class="col-6 col-md-2 quantity">';
				snippet += '<label class="d-none d-md-block" for="quantity">Quantité</label><input type="number" id="number" class="form-control quantity-input" value="'+item.product_quantity+'" min="1" onchange="App.changeProductQuantity(\''+index+'\', this.value, \'page\')">';
				snippet += '<button class="btn btn-sm btn-danger btn-block" onclick="App.removeItemFromCart(\''+index+'\', \'page\')"><i class="fa fa-trash-alt"></i></button>';
				snippet += '</div>'; // col-md-2
				const priceFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.product_price*item.product_quantity);
				snippet += '<div class="col-6 col-md-2 font-weight-bolder"><span>'+priceFormat+'</span></div>';
				snippet += '</div>'; // row
				snippet += '</div>'; // product
				$(cartDiv+' #itemsInCart').append(snippet);
				priceTotal = priceTotal + item.product_price*item.product_quantity;
			});
		}
		else {
			$(cartDiv+' #itemsInCart').append('<h2>Votre panier est vide !</h2><img src="https://rassure.store/fonts/undraw_empty_cart_co35.svg" class="img-fluid"/>');
			globals.priceDelivery = 0;
		}
		globals.priceTotal = priceTotal;
		$.sessionStorage.setItem('priceTotal', priceTotal);
		let priceDelivery = (isNaN(globals.priceDelivery) || globals.priceDelivery==null) ? 0 : globals.priceDelivery;
		const priceSubTotal = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal);
		priceTotal = Number(priceTotal)+Number(priceDelivery);
		const priceVat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal/6);
		priceTotal = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal);
		priceDelivery = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceDelivery);
		$(cartDiv+' #cartTotal').text(priceTotal);
		$(cartDiv+' #cartSubTotal').text(priceSubTotal);
		$(cartDiv+' #cartVat').text(priceVat);
		$(cartDiv+' #cartShipping').text(priceDelivery);
		$(cartDiv+' #placeOrderBtn').attr('href', nextPage); // default link is /login
		// Set the LightBox...
		let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
			showCounter : false,
			history : false,
			captionType : 'data',
			captionsData : 'caption',
			maxZoom: 1,
			widthRatio: 0.6,
			heightRatio: 0.6,
		});
	},
	setContactPage: function() {
		App.smoothScrollTo('#contactFormCont', 0);
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Contact - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Contactez-Nous');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setAboutPage: function() {
		App.smoothScrollTo('#aboutCont', 0);
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Qui sommes-nous - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Qui sommes-nous');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setHomePage: function() {
		App.getProductList();
		App.smoothScrollTo('#productsListCont', 0);
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Accueil - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Accueil');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setRegisterPage: function() {
		App.smoothScrollTo('#registerCont', 0);
		// Company account & registration
		$('#id_gender').on('change', function() {
			if(this.value==3) $('.companyFields').css('display', 'flex');
			else $('.companyFields').hide();
		});
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Enregistrement - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Enregistrement');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setLoginPage: function() {
		App.smoothScrollTo('#loginCont', 0);
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Connexion - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Connexion');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setAccountPage: function() {
		App.smoothScrollTo('#accountCont', 0);
		$('#updateAccountForm #id_gender').val(globals.id_gender_u);
		$('#updateAccountForm #firstname').val(globals.firstname_u);
		$('#updateAccountForm #lastname').val(globals.lastname_u);
		$('#updateAccountForm #email').val(globals.email_u);
		$('#updateAccountForm #phone').val(globals.phone_u);
		// $('#updateAccountForm #password').val(globals.password_u);
		// const $bDayParts = globals.birthday_u.split('-');
		// const bDayFrench = $bDayParts[2]+'/'+$bDayParts[1]+'/'+$bDayParts[0];
		$('#updateAccountForm #birthday').val(globals.birthday_u);
		$('#updateAccountForm #delivery_address_s').val(globals.delivery_address_u);
		$('#updateAccountForm #delivery_address2_s').val(globals.delivery_address2_u);
		$('#updateAccountForm #delivery_zip_s').val(globals.delivery_zip_u);
		$('#updateAccountForm #delivery_city_s').val(globals.delivery_city_u);
		$('#updateAccountForm #delivery_country_s').val(globals.delivery_country_u);
		$('#updateAccountForm #invoice_address_s').val(globals.invoice_address_u);
		$('#updateAccountForm #invoice_address2_s').val(globals.invoice_address2_u);
		$('#updateAccountForm #invoice_zip_s').val(globals.invoice_zip_u);
		$('#updateAccountForm #invoice_city_s').val(globals.invoice_city_u);
		$('#updateAccountForm #invoice_country_s').val(globals.invoice_country_u);
		// Company account & registration
		$('#id_gender').on('change', function() {
			if(this.value==3) $('.companyFields').css('display', 'flex');
			else $('.companyFields').hide();
		});
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Mon Compte - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Mon compte');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setCodeGenPage: function(qrId) {
		App.smoothScrollTo('#codeGenCont', 0);
		$('#customCodesForm #idOrderLine').val(globals.idOrderLine); // get The last idOrderLine as it has to be the one we need...
		if(isNaN(qrId) || qrId==null) { // If no qrId provided we set it to zero so that we'll generate a new one...
			globals.qrId = 0;
			$.sessionStorage.setItem('qrId', 0);
		}
		else { // If qrId is provided in the URL and is a number we use it...
			globals.qrId = qrId;
			$.sessionStorage.setItem('qrId', qrId);
		}
		$('#qrImgBg').on('change', function() {
			// We have to upload this picture as it is probably a new one...
			globals.uploadQrBgImg = true;
			$('#qrPositionCont').fadeIn();
		});
		// Set the LightBox...
		let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
			showCounter : false,
			history : false,
			captionType : 'data',
			captionsData : 'caption',
			maxZoom: 1,
			widthRatio: 0.6,
			heightRatio: 0.6,
		});
		// If a QrCode has been validated earlier we propose to use it again... We also search for uploadable_files value for this item !
		let snippet = '';
		let uploadable_files = 0; // Set default
		// Finding the product in cart...
		const thisProduct = globals.cart.find(item => item.idOrderLine == globals.idOrderLine);
		uploadable_files = thisProduct.uploadable_files;
		const framed = thisProduct.frame;
		const product_ref = thisProduct.product_ref;
		if(globals.cart.length>0) {
			globals.cart.forEach((item, index) => {
				// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
				// Is this qrCode is validated and customizable the same way
				if(item.code_valid==1 && uploadable_files >= item.uploadable_files) {
					snippet += '<div class="form-check form-check-inline pl-2">';
					snippet += '<input class="form-check-input" type="radio" onclick="App.validateCustomCodes('+item.id_code+', '+globals.idOrderLine+', true)" name="qrUseOld" id="qrUseOld'+item.id_code+'" value="item.id_code">';
					snippet += '<label class="form-check-label" for="qrUseOld'+item.id_code+'">';
					// snippet += '<a href="https://rassure.store/codes/code_'+item.id_code+'.png" data-simplelightbox="all">';
					snippet += '<img src="https://rassure.store/codes/code_'+item.id_code+'.png" class="img-thumbnail" width="196px" alt="code_'+item.id_code+'">';
					// snippet += '</a>';
					snippet += '</label>';
					snippet += '</div>';
				}
			});
			// Is there an element to display ?
			if(snippet != '') {
				$('#qrUseOldCont').append('<div class="alert alert-info mb-2"><h4>Utilser un des QrCodes précédents :</h4>'+snippet+'</div>');
				lightbox.refresh();
			}
			/*
			const thereIs = globals.cart.find(item => item.code_valid == 1);
			if(thereIs !== undefined) {
				$('#qrUseOldCont').append(snippet);
				lightbox.refresh();
			}
			*/
			// If uploadable_files is set to 0 then we disable #qrImgBg
			if(uploadable_files!=0) $('#qrImgBg').attr("disabled", false);
			else $('#qrImgBg').attr("disabled", true);
			if(framed=='framed') {
				$('#qrFramed').val(framed);
				$('#productRef').val(product_ref);
				$('#qrPreviewCont a').attr("href", "https://rassure.store/codes/frames/"+product_ref+'.png');
				$('#qrPreviewCont a img').attr("src", "https://rassure.store/codes/frames/"+product_ref+'.png');
				lightbox.refresh();
				$('#qrColorBg').attr("disabled", true);
				$('#qrOpacityBg').attr("disabled", true);
			}
			else {
				$('#qrColorBg').attr("disabled", false);
				$('#qrOpacityBg').attr("disabled", false);
			}
		}
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Générateur de QrCodes - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Générateur de QrCodes');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setCodeModPage: function(qrId) {
		if(globals.pass == 'OK') {
			App.smoothScrollTo('#codeModCont', 0);
			if(isNaN(qrId) || qrId==null) { // If no qrId provided we set it to zero so we list all of them...
				qrId = 0;
			}
			const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
			const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
			$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'qrId': qrId, "req": "getQrCodeMod"}, function(data){
				if(data.ok=='ok') {
					$('#codeModCont').empty().append(data.snippet);
				}
				else $('#codeModCont').empty().append('<h2 class="text-light">Aucun QrCode n\'a été trouvé pour votre compte !</h2><img src="https://rassure.store/fonts/undraw_blank_canvas_-3-rbb.svg" class="img-fluid"/>');
			}, "json").always(function(){
				// Set the LightBox...
				let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
					showCounter : false,
					history : false,
					captionType : 'data',
					captionsData : 'caption',
					maxZoom: 1,
					widthRatio: 0.6,
					heightRatio: 0.6,
				});
			});
			// Building meta tags list...
			$('head .productMeta').remove(); // In case there are some...
			$('head title').text('Générateur de QrCodes - Rassure.Store');
			$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Générateur de QrCodes');
			$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
		}
		else document.location.href='/login';
	},
	setDeliveryPage: function() {
		this.smoothScrollTo('#deliveryCont', 0);
		this.buildCartPopUpModal(false);
		$('#deliveryChoiceCont').empty();
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder;
		const idCountry = (isNaN(globals.idCountry) || globals.idCountry==null) ? 8 : globals.idCountry; // Defaults to French
		let priceTotal = 0;
		if(globals.cart.length>0) {
			globals.cart.forEach((item, index) => {
				priceTotal = priceTotal + item.product_price*item.product_quantity;
			});
		}
		else $('#deliveryChoiceCont').append('<h2>Votre panier est vide !</h2><img src="https://rassure.store/fonts/undraw_empty_cart_co35.svg" class="img-fluid"/>');
		globals.priceTotal = priceTotal;
		$.sessionStorage.setItem('priceTotal', priceTotal);
		$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'idOrder': idOrder, 'idCountry': idCountry, 'priceTotal': priceTotal, "req": "getDeliveryChoice"}, function(data){
			if(data.ok=='ok') {
				$('#deliveryChoiceCont').append(data.snippet);
			}
			else $('#deliveryChoiceCont').append('<h2>Aucun transporteur disponible !</h2><img src="https://rassure.store/fonts/undraw_Delivery_re_f50b.svg" class="img-fluid"/>');
		}, "json").always(function(){
			priceTotal = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(priceTotal);
			$('#deliveryChoiceHeader').empty().append('<div class="row"><div class="col-md-6"><h2>Total TTC : '+priceTotal+'</h2></div><div class="col-md-6"><h2><a href="#addToCartModal" class="text-warning noscroll" data-toggle="modal" style="float: right;"><i class="fa fa-shopping-cart"></i> Voir le Détail</a></h2></div></div><hr>');
		});
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Livraison - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Livraison');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setOrderPage: function() {
		this.smoothScrollTo('#orderPageCont', 0);
		this.buildCart('#orderPageCont', '/paypage');
		// this.buildCartPopUpModal(false);
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Commande - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Commande');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	setPayPage: function() {
		App.smoothScrollTo('#payPageCont', 0);
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder;
		const idCountry = (isNaN(globals.idCountry) || globals.idCountry==null) ? 8 : globals.idCountry; // Defaults to French
		let priceTotal = 0;
		if(globals.cart.length>0) {
			globals.cart.forEach((item, index) => {
				priceTotal = Number(priceTotal + item.product_price*item.product_quantity);
			});
		}
		else {
			alert('Votre panier est vide !');
			document.location.href='/';
		}
		console.log(globals.delivery_country_u);
		console.log(globals.invoice_country_u);
		function initPayPalButton() {
			const shippingCountryCode = globals.i18nIsoCountries.find(item => item.country == globals.delivery_country_u).code;
			const invoiceCountryCode = globals.i18nIsoCountries.find(item => item.country == globals.invoice_country_u).code;
			console.warn("Total: "+priceTotal.toFixed(2)+" - "+shippingCountryCode+" - "+invoiceCountryCode+" - Order #"+globals.idOrder);
			paypal.Buttons({
				style: {
					shape: 'rect',
					color: 'gold',
					layout: 'vertical',
					label: 'paypal',
				},
				createOrder: function(data, actions) {
					// https://developer.paypal.com/docs/api/orders/v2/#definition-purchase_unit_request
					return actions.order.create({
						purchase_units: [{
							amount:{
								"currency_code": "EUR",
								"value": priceTotal.toFixed(2)
							},
							invoice_id: "#Rassure_"+idOrder,
							shipping: {
								name: {
									full_name: globals.firstname_u+" "+globals.lastname_u,
								},
								address: {
									address_line_1: globals.delivery_address_u,
									address_line_2: globals.delivery_address2_u,
									admin_area_1: globals.delivery_city_u,
									admin_area_2: globals.delivery_country_u,
									postal_code: globals.delivery_zip_u,
									country_code: shippingCountryCode
								}
							},
							// "amount":{"currency_code":"EUR","value":30,"breakdown":{"item_total":{"currency_code":"EUR","value":20},"shipping":{"currency_code":"EUR","value":6},"tax_total":{"currency_code":"EUR","value":4}}}
						}],
						payer: {
							name: {
								given_name: globals.firstname_u,
								surname: globals.lastname_u,
							},
							email_address: globals.email_u,
							phone: {
								phone_type: "MOBILE",
								phone_number: {
									national_number: globals.phone_u.replaceAll(" ", "").replaceAll(".", "").replaceAll("-", ""),
								}
							},
							address: {
								address_line_1: globals.invoice_address_u,
								address_line_2: globals.invoice_address2_u,
								admin_area_1: globals.invoice_city_u,
								admin_area_2: globals.invoice_country_u,
								postal_code: globals.invoice_zip_u,
								country_code: invoiceCountryCode
							}
						},
					});
				},
				onApprove: function(data, actions) {
					return actions.order.capture().then(function(orderData) {
						// Full available details
						console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
						/*
						Returned Object
						{
							"id": "61G468900N757754J",
							"intent": "CAPTURE",
							"status": "COMPLETED",
							"purchase_units": [
								{
								"reference_id": "default",
								"amount": {
									"currency_code": "EUR",
									"value": "9.90"
								},
								"payee": {
									"email_address": "sb-v9bxg20496943@business.example.com",
									"merchant_id": "94VQZ36NCU54N"
								},
								"invoice_id": "#Rassure_227",
								"shipping": {
									"name": {
									"full_name": "John DOE"
									},
									"address": {
									"address_line_1": "3 cours Franklin Roosevelt",
									"admin_area_2": "France",
									"admin_area_1": "MARSEILLE",
									"postal_code": "13001",
									"country_code": "FR"
									}
								},
								"payments": {
									"captures": [
									{
										"id": "12F577652N009611R",
										"status": "COMPLETED",
										"amount": {
										"currency_code": "EUR",
										"value": "9.90"
										},
										"final_capture": true,
										"seller_protection": {
										"status": "ELIGIBLE",
										"dispute_categories": [
											"ITEM_NOT_RECEIVED",
											"UNAUTHORIZED_TRANSACTION"
										]
										},
										"invoice_id": "#Rassure_227",
										"create_time": "2022-08-30T12:13:00Z",
										"update_time": "2022-08-30T12:13:00Z"
									}
									]
								}
								}
							],
							"payer": {
								"name": {
								"given_name": "John",
								"surname": "Doe"
								},
								"email_address": "sb-t147eg20242451@personal.example.com",
								"payer_id": "LCCPRWZV4X55C",
								"address": {
								"address_line_1": "3 cours Franklin Roosevelt",
								"admin_area_2": "France",
								"admin_area_1": "MARSEILLE",
								"postal_code": "13001",
								"country_code": "FR"
								}
							},
							"create_time": "2022-08-30T12:12:07Z",
							"update_time": "2022-08-30T12:13:00Z",
							"links": [
								{
								"href": "https://api.sandbox.paypal.com/v2/checkout/orders/61G468900N757754J",
								"rel": "self",
								"method": "GET"
								}
							]
						}
						*/
						// Show a success message within this page, e.g.
						const element = document.getElementById('paypal-button-container');
						element.innerHTML = '';
						element.innerHTML = '<h3>Merci pour votre paiement, nous validons votre commande...</h3>';
						// Or go to another URL:  actions.redirect('thank_you.html');
						document.location.href='/confirmation';
					});
				},
				onError: function(err) {
					console.log(err);
				}
			}).render('#paypal-button-container');
		}
		loadScript({ "client-id": "AbvcwYjT28q7xakI-jMU-nOjHO7yLLSg9_yuoPjANjgQu-N-7YN2Xjlxiw8FT8c2MN-G3D59ZIxZE1eJ", "currency": "EUR", "enable-funding": "venmo" })
		.then((paypal) => {
			// paypal.Buttons().render("#your-container-element").catch((error) => {console.error("failed to render the PayPal Buttons", error);});
			initPayPalButton();
		})
		.catch((error) => {
			console.error("failed to load the PayPal JS SDK script", error);
		});
		// initPayPalButton();
		// Building meta tags list...
		$('head .productMeta').remove(); // In case there are some...
		$('head title').text('Paiement - Rassure.Store');
		$('head meta[name="description"]').text('Rassure.Store - Créez des QrCodes dynamiques et personnalisés - Paiement');
		$('head meta[name="keywords"]').text('Rassure.Store, Creation, QrCodes, dynamique, personnalisé, qr codes');
	},
	selectDelivery: (idCarrier, idOrder, priceTotal, priceDelivery) => {
		globals.idCarrier = idCarrier;
		globals.priceDelivery = priceDelivery;
		globals.priceTotal = priceTotal; // Here priceTotal includes delivery fees...
		$.sessionStorage.setItem('idCarrier', idCarrier);
		$.sessionStorage.setItem('priceDelivery', priceDelivery);
		$.sessionStorage.setItem('priceTotal', priceTotal);
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'idOrder': idOrder, 'idCarrier': idCarrier, 'priceTotal': priceTotal, "req": "selectDelivery"}, function(data){
			if(data.ok=='ok') {
				document.location.href='/order';
			}
			else alert('Nous ne pouvons donner suite à votre demande suite à un problème technique, veuillez nous contacter afin de nous faire part de cette erreur.');
		}, "json").always(function(data){
		});
	},
	checkRadioById: function (radioId, triggerClick) {
		$('#'+radioId).attr('checked', true);
		if(triggerClick) $('#'+radioId).trigger('click');
	},
	emptyThisField: function (myFieldId, event) {
		event.preventDefault();
		$(myFieldId).val(""); // Empty the input field
		$(myFieldId).siblings(".custom-file-label").removeClass("selected").html(''); // Empty Bootstrap's custom-file
	},
	generateCustomCodes: function (myFormDiv) {
		let qrId = globals.qrId;
		if(isNaN(qrId) || globals.qrId==null) qrId = 0; // Just in case but very unlikely to happen as sessionStorage item is only set here.
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder;
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const pictureName = globals.pictureName;
		const qrStyle = $(myFormDiv+' #qrStyle').val();
		const qrColorMain = $(myFormDiv+' #qrColorMain').val();
		const qrColorSec = $(myFormDiv+' #qrColorSec').val();
		let qrColorBg = $(myFormDiv+' #qrColorBg').val();
		let qrOpacityBg = $(myFormDiv+' #qrOpacityBg').val();
		const idOrderLine = $(myFormDiv+' #idOrderLine').val();
		const qrImgBg = $(myFormDiv+' #qrImgBg').val();
		const qrPosition = $(myFormDiv+' #qrPosition').val();
		const qrColorMainIsLight = tinycolor(qrColorMain).isLight();
		const qrColorSecIsLight = tinycolor(qrColorSec).isLight();
		console.warn('qrColorMain: '+qrColorMain+', qrColorSec: '+qrColorSec+', qrColorBg: '+qrColorBg+', idOrder: '+idOrder+', idOrderLine: '+idOrderLine+', qrImgBg: '+qrImgBg+', qrColorMainIsLight: '+qrColorMainIsLight+', qrColorSecIsLight: '+qrColorSecIsLight);
		if(qrImgBg != '') { // If an image is uploaded we force filter light or dark...
			if(qrColorMainIsLight || qrColorSecIsLight) qrColorBg = '#000000';
			// else if(qrColorMainIsLight && !qrColorSecIsLight) qrColorBg = '#333333';
			// else if(!qrColorMainIsLight && qrColorSecIsLight) qrColorBg = '#CCCCCC';
			else qrColorBg = '#FFFFFF';
			if(qrOpacityBg<=0.5 && qrPosition=='cover') {
				qrOpacityBg = (qrStyle=='dots') ? 0.6 : 0.5;
			}
		}
		else qrOpacityBg = 1; // In case there is no image we set filter opacity to 1
		$(myFormDiv+' #qrColorBg').val(qrColorBg);
		$(myFormDiv+' #qrOpacityBg').val(qrOpacityBg);
		// const qrColorMainIsReadable = tinycolor.isReadable(qrColorMain, qrColorBg,{level:"AA",size:"large"});
		const contrastFactor = (qrStyle=='dots') ? 2 : 1.5;
		const qrColorMainIsReadable = (tinycolor.readability(qrColorMain, qrColorBg)>contrastFactor) ? true : false;
		const qrColorSecIsReadable = (tinycolor.readability(qrColorSec, qrColorBg)>contrastFactor) ? true : false;
		// const testIsReadable = tinycolor.readability("#999", "#FFF");
		let warning = "";
		warning = (!qrColorMainIsReadable) ? warning+"La couleur principale est trop proche de la couleur du fond !\r\n" : warning;
		warning = (!qrColorSecIsReadable) ? warning+"La couleur secondaire est trop proche de la couleur du fond !\r\n" : warning;
		// warning = (!testIsReadable) ? warning+"La couleur testIsReadable est illisible !\r\n" : warning;
		if(warning != '') alert("Le QrCode risque d'être illisible car :\r\n"+warning);
		// if(qrColorMain==qrColorBg || qrColorSec==qrColorBg) alert("La couleur du QrCode est la couleur de fond ne peuvent pas être les mêmes");
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		$('#qrPreviewCont').empty().append('<img src="https://rassure.store/loading.svg" class="img-fluid" alt="Chargement...">');
		const req = "generateCustomCodes";
		// let query = $(myFormDiv).serialize();
		// query = query + "&req=" + req + "&id=" + globals.id + "&pwd=" + globals.pwd;
		let request = new FormData($(myFormDiv)[0]);
		request.append("id", id);
		request.append("idOrder", idOrder);
		request.append("qrId", qrId);
		request.append("pictureName", pictureName);
		// request.append("pwd", globals.pwd);
		// Do we upload a new picture ?
		if(!globals.uploadQrBgImg && pictureName!=null && qrImgBg!="") {
			request.delete('qrImgBg[]');
			request.append("usePreviousPicture", true);
		}
		globals.uploadQrBgImg = false;
		request.append("req", req);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data) {
			if(data.ok=="ok") {
				$('#qrPreviewCont').empty().append(data.snippet);
				// $(myFormDiv+' #qrId').val(data.qrId);
				$.sessionStorage.setItem('qrId', data.qrId);
				$.sessionStorage.setItem('pictureName', data.pictureName);
				globals.qrId = data.qrId;
				globals.pictureName = data.pictureName;
				globals.cart[globals.cart.length-1].id_code = data.qrId;
				$.sessionStorage.setItem('cart', JSON.stringify(globals.cart));
				console.log(globals.cart);
				// $('#qrPreviewCont a').attr('href', data.previewURL);
				// $('#qrPreviewCont img').attr('src', data.previewURL);
				$('#validateCustomCodesCont').empty().append('<button class="btn btn-dark btn-block text-white" id="validateCustomCodesBtn" onclick="App.validateCustomCodes(\''+data.qrId+'\', \''+idOrderLine+'\', false);"><b><i class="fa fa-check-circle"></i> Valider / Continuer</b></button>');
			}
			else
				alert("Une erreur s'est produite !!");
			let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
				showCounter : false,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				maxZoom: 1,
				widthRatio: 0.6,
				heightRatio: 0.6,
			});
		}).always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-retweet"></i> Générer mon QrCode</b>');
		});
	},
	qrTypeChange: function(mySelect) {
		const prefix = mySelect.value;
		console.log(prefix);
		// $('#qrValue').val(prefix);
		if(prefix=='http://') { // In this case we just want the prefix to be the placeholder
			$(mySelect).next('input').val('');
			$(mySelect).next('input').attr('placeholder', prefix);
		}
		else {
			$(mySelect).next('input').val(prefix);
			$(mySelect).next('input').attr('placeholder', prefix);
		}
	},
	validateCustomCodes: function (qrId, idOrderLine, confirmBox) {
		let hereWeGo = (confirmBox) ? false : true;
		if(confirmBox) hereWeGo = confirm("Validez-vous l'utilisation de ce QrCode ?\r\n(Vous l'avez testé et êtes certain qu'il fonctionne)");
		if(hereWeGo) {
			// let qrId = globals.qrId;
			if(isNaN(qrId) || globals.qrId==null) qrId = 0;
			const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
			const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
			const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case but very unlikely to happen as sessionStorage item is only set here.
			if(qrId!=0) {
				// Finding the product in cart...
				const thisProduct = globals.cart.find(item => item.idOrderLine == idOrderLine);
				const foundIndex = globals.cart.findIndex(item => item.idOrderLine == idOrderLine);
				// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
				const id_product = thisProduct.id_product;
				const id_product_attribute = thisProduct.id_product_attribute;
				const product_price = thisProduct.product_price;
				const product_quantity = thisProduct.product_quantity;
				const product_name = thisProduct.product_name;
				const product_choices = (thisProduct.product_choices.length > 0) ? thisProduct.product_choices.join(' | ') : '';
				$('#validateCustomCodesBtn').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
				$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'idOrder': idOrder, 'idOrderLine': idOrderLine, 'qrId': qrId, 'id_product': id_product, 'id_product_attribute': id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_choices': product_choices, "req": "validateCustomCodes"}, function(data){
					if(data.ok=='ok') {
						document.location.href='/cart';
						// $.sessionStorage.setItem('idOrder', data.idOrder);
						// globals.idOrder = data.idOrder;
						globals.cart[foundIndex].code_valid = 1;
						globals.cart[foundIndex].id_code = qrId; // In case we selected an old QrCode
						$.sessionStorage.setItem('cart', JSON.stringify(globals.cart));
						console.log(globals.cart);
						// App.buildCartPopUpModal(false);
						// $('#addToCartModal').modal('show');
					}
					else alert("Suite à un problème technique je ne peux donner suite à votre demande, désolé.\nErreur : QrCode ID = "+qrId);
				}, "json").always(function(data){
					$('#validateCustomCodesBtn').attr("disabled", false).html('<b><i class="fa fa-check-circle"></i> Valider / Continuer</b>');
				});
			}
			else alert("Suite à un problème technique je ne peux donner suite à votre demande, désolé.\nErreur : QrCode ID = "+qrId);
		}
	},
	checkvalidCustomCodes: async function () { // Basically it was made to validate all unvalidated QrCodes in the order but here we now make sure that each one has a User Id...
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case but very unlikely to happen as sessionStorage item is only set here.
		// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
		if(globals.cart.length>0) {
			globals.cart.forEach((item, index) => {
				// if(item.code_valid==0) {
				const product_choices = (item.product_choices) ? item.product_choices.join(' | ') : '';
				$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'idOrder': idOrder, 'idOrderLine': item.idOrderLine, 'qrId': item.id_code, 'id_product': item.id_product, 'id_product_attribute': item.id_product_attribute, 'product_price': item.product_price, 'product_quantity': item.product_quantity, 'product_name': item.product_name, 'product_choices': product_choices, "req": "validateCustomCodes"}, function(data){
					if(data.ok=='ok') {
						$.sessionStorage.setItem('idOrder', data.idOrder);
						globals.idOrder = data.idOrder;
						globals.cart[index].code_valid = 1;
						$.sessionStorage.setItem('cart', JSON.stringify(globals.cart));
						console.log(globals.cart);
					}
					else console.log("Suite à un problème technique je ne peux donner suite à votre demande, désolé.\nErreur : QrCode ID = "+qrId);
				}, "json").always(function(data){
				});
				// }
				console.log(item);
			});
		}
	},
	editCustomCodes: function(qrId, thisBtn) {
		const qrType = $('#qrType_'+qrId).val();
		const qrValue = $('#qrValue_'+qrId).val();
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		if(qrValue!='') {
			$.post(globals.serverAddress, {'id': id, 'idPresta': idPresta, 'qrId': qrId, 'qrType': qrType, 'qrValue': qrValue, 'req': 'editCustomCodes'}, function(data){
				if(data.ok=='ok') {
					alert('Le QrCode a bien été modifié !');
				}
				else alert('Le QrCode n\'a pas été modifié suite à un problème technique !');
			}, "json").always(function(){
				$(thisBtn).attr("disabled", false).html('<b><i class="fa fa-save"></i> Enregistrer</b>');
			});
		}
		else alert("Vous devez renseigner une valeur !");
	},
	sendOrder: async function() {
		App.smoothScrollTo('#confirmationPageCont', 0);
		// WARNING REMEMBER TO VALIDATE ALL QrCodes IN THE CART THAT ARE NOT VALIDATED YET (check idOrder, cart and tables front_orders & front_orders_lines)
		let checkCart = await this.checkvalidCustomCodes();
		console.log("Promise is done : checkvalidCustomCodes has validated cart's QrCodes");
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const idPresta = (isNaN(globals.idPresta) || globals.idPresta==null) ? 0 : globals.idPresta;
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case but very unlikely to happen as sessionStorage item is only set here.
		if(globals.cart.length>0) {
			// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
			$('#confirmationCont').empty().append('<i class="fa fa-2x fa-spinner fa-pulse"></i><hr><h3>Merci pour votre achat, nous validons votre commande... Veuillez patienter</h3>');
			$.post(globals.serverAddress, {'id': id, 'customerId': idPresta, 'idOrder': idOrder, 'email': globals.email, 'req': 'sendOrder'}, function(data){
				if(data.ok=='ok') {
					$('#confirmationCont').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle"></i>&nbsp;'+data.alertSentence+'</b></div>');
					// $('#confirmationCont').empty().append('<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle"></i>Votre commande a bien été traitée, merci pour cet achat. Vous pouvez maintenant télécharger le(s) QrCode(s) généré(s), vous recevrez une confirmation par email...</b></div>');
					let snippet = '<div class="col-12 text-center my-2"><h3>Vous pouvez dès à présent télécharger votre commande ci-dessous</h3></div>';
					globals.cart.forEach((item, index) => {
						// let cartItem = {'idOrderLine': data.idOrderLine, 'id_product': id_product, 'id_product_attribute': data.id_product_attribute, 'product_price': product_price, 'product_quantity': product_quantity, 'product_name': product_name, 'product_desc': product_desc, 'product_img': product_img, 'product_ref': product_ref, 'customizable': 1, 'uploadable_files': 1, 'frame':framed, 'product_choices': product_choices, 'id_code': 0, 'code_valid': 0};
						snippet += '<div class="col-auto d-grid text-center mx-auto">';
						snippet += '<a href="https://rassure.store/codes/code_'+item.id_code+'.png" title="Télécharger" download>';
						// snippet += '<a href="https://rassure.store/codes/code_'+item.id_code+'.png" data-simplelightbox="all">';
						snippet += '<img src="https://rassure.store/codes/code_'+item.id_code+'.png" class="img-fluid" style="max-width:640px" alt="code_'+item.id_code+'">';
						snippet += '<h4>Télécharger</h4>';
						snippet += '</a>';
						snippet += '</div>';
					});
					$('#confirmationCont').append('<div class="row">'+snippet+'</div>');
					// Empty the cart
					$.sessionStorage.removeItem('cart'), $.sessionStorage.removeItem('idOrder'), $.sessionStorage.removeItem('idOrderLine');
					globals.cart = [], globals.idOrder = 0, globals.idOrderLine = 0
					$('#cartBadge').empty();
				}
				else if(data.alertSentence) $('#confirmationCont').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle"></i>&nbsp;'+data.alertSentence+'</b></div>');
				else $('#confirmationCont').empty().append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle"></i>&nbsp;Votre commade n\'a pas été traitée convenablement à cause d\'un problème technique.</b></div>');
			}, "json").always(function(data){
				// $('#confirmationCont #sender').attr("disabled", false).html('<i class="fa fa-credit-card"></i> Commander');
			});
		}
		else {
			$('#confirmationCont').append('<h2>Votre panier est vide !</h2><img src="https://rassure.store/fonts/undraw_empty_cart_co35.svg" class="img-fluid"/>');
		}
	},
	bindUIActions: function() {
		// In case there is a page refresh we get back the session cart array of Objects
		globals.cart = ($.sessionStorage.getItem('cart') != null) ? App.safeJsonParse($.sessionStorage.getItem('cart')) : [];
		console.log(globals.cart);
		// Disable right click menu
		window.addEventListener("contextmenu", (e) => e.preventDefault(), false);
		// document.querySelectorAll('img').forEach(function(item) {item.addEventListener("contextmenu", (e) => e.preventDefault(), false);});
		// Udating cartBadge in main menu
		globals.cartBadge = globals.cart.length;
		if(globals.cartBadge>0) $('#cartBadge').empty().append(globals.cartBadge);
		if(globals.pass == "OK") {
			// $('#mainMenuAccountLink').attr('href', '/account'); // default link is /login
			$('#userNameCont').empty().append('Bienvenue<br>'+globals.firstname_u+' '+globals.lastname_u);
			$('.connected').show();
			$('.notConnected').hide();
		}
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		// Add the following code if you want the name of the file appear on select
		$(".custom-file-input").on("change", function(e) {
			//const fileName = $(this).val().split("\\").pop();
			let fileName = "";
			//e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
			//alert(e.target.files[0].name);
			for (var i = 0; i < e.target.files.length; i++) {
				fileName += '"'+e.target.files[i].name+'" ';
			}
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		$.sessionStorage.setItem('myPage', globals.myPage);
		// And set the LightBox...
		let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
			showCounter : false,
			history : false,
			captionType : 'data',
			captionsData : 'caption'
		});
		lightbox.refresh();
		// Set zoom & LightBox on product page
		if ($('.clean-product').length > 0) {
			$('.sp-wrap').smoothproducts();
		}
		// Set swipe gestures on smoohtproduct's lightbox
		$('.sp-lightbox').on('swipeleft',function(){
			$('a.sp-prev').trigger('click');
		});
		$('.sp-lightbox').on('swiperight',function(){
			$('a.sp-next').trigger('click');
		});
		// Set smoothScroll on anchors
		this.refreshSmoothScroll();
		// Floating label headings for the contact form
		$("body").on("input propertychange", ".floating-label-form-group", function(e) {
			$(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
		}).on("focus", ".floating-label-form-group", function() {
			$(this).addClass("floating-label-form-group-with-focus");
		}).on("blur", ".floating-label-form-group", function() {
			$(this).removeClass("floating-label-form-group-with-focus");
		});
		// Close Dropdown AccountMenu when one of its links are clicked
		$("#mainMenuAccounDropdown .dropdown-item").on("click", function() {
			$(this).closest('.dropdown-menu').removeClass('show');
		});
		// Show the navbar when the page is scrolled up
		const MQL = 992;
		//primary navigation slide-in effect
		var headerHeight = $('#mainNav').height();
		$(window).on('scroll', {
			previousTop: 0
		}, function() {
			var currentTop = $(window).scrollTop();
			if ($(window).width() > MQL) {
				//check if user is scrolling up
				if (currentTop < this.previousTop) {
					//if scrolling up...
					if (currentTop > 0 && $('#mainNav').hasClass('is-fixed')) {
						$('#mainNav').addClass('is-visible');
					} else {
						$('#mainNav').removeClass('is-visible is-fixed');
					}
				} else if (currentTop > this.previousTop) {
					//if scrolling down...
					$('#mainNav').removeClass('is-visible');
					if (currentTop > headerHeight && !$('#mainNav').hasClass('is-fixed')) $('#mainNav').addClass('is-fixed');
					$('.dropdown-menu').removeClass('show'); // dropdown-menu bugs when on mobile devices (inside mobile collapse)
				}
			}
			else {
				// Mobile version...
				$('#mainNav').removeClass('is-visible').removeClass('is-fixed'); // In case We switched from wide screen to Mobile.
				$('.dropdown-menu').addClass('show'); // dropdown-menu bugs when on mobile devices (inside mobile collapse)
				if (currentTop < this.previousTop) {
					//if scrolling up...
					$('#mainNav').fadeIn("slow");
				} else if (currentTop > this.previousTop) {
					//if scrolling down...
					$('#mainNav').fadeOut("slow");
				}
			}
			this.previousTop = currentTop;
		});
		$('#mainNav .nav-link').on("click", function () {
			if(window.innerWidth < MQL) {
				//$('.navbar').slideToggle("slow");
				$('#mainNav .navbar-collapse').removeClass('show');
				//alert(window.innerWidth);
			}
		});
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					// $('.navbar').slideDown("slow");
					// $('#navbar-collapse').removeClass('show');
					$('.go-up-fixed').fadeOut('slow');
				}
				else
					$('.go-up-fixed').fadeIn('slow');
			}
		});
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
	smoothScrollTo: function(target, offset) {
		// Smooth Scroll to div...
        if($(target).length) {
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 1600, 'swing', function() {
                // window.location.hash = target;
            });
        }
	},
	//below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.pageYOffset ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.pageYOffset;
			scrOfX = window.pageXOffset;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},
	//taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
require('./router.js');

(function() {
	App.init();
})();